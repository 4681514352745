import { nanoid } from "nanoid";
import TeamProps from "../types/team-props";
import TeamStateRolesProps from "../types/team-state-roles-props";
import TEAM_STATES_SUGGESTIONS, {
  DEFAULT_TEAM_SUGGESTION,
} from "../assets/constants/teamStatesSuggestions";
import calculateCPSP, { getCPSPColorCode } from "./calculateCPSP";
import {
  buildStateRoleWithRoleDTO,
  getScenarioMembersTotalSalary,
  replaceByNumber8,
} from "./scenarioResources";
import RoleProps from "../types/role-props";
import TeamValuesEnum from "../assets/constants/TeamValuesEnum";
import TeamsCurrentAndFutureMembersProps from "../types/teams-current-and-future-members-props";
import TeamScenarioProps from "../types/team-scenario-props";
import TeamScenarioResourceProps from "../types/team-scenariou-resource-props";
import ResourceProp from "../types/resource-prop";
import ResourceWithRoleProps from "../types/resource-with-role-props";
import TeamScenarioOperationsEnum, {
  operationsAllowedWhenThereIs1Scenario,
  operationsAllowedWhenTheScenarioIsTheLast,
  operationsAllowedOnlyOnFirstAndSecondScenario,
} from "../assets/constants/TeamScenarioOperationsEnum";
import RoleAmountProps from "../types/role-amount-props";
import {
  RoleSeniority,
  RoleSeniorityList,
} from "../assets/constants/RoleSeniority";

const sumReducer = (prev: number, curr: number) => curr + prev;

function allowOperationToScenario(
  scenarios: TeamScenarioProps[],
  scenarioId: string,
  teamId: string,
  operation: TeamScenarioOperationsEnum
): boolean {
  const scenariosByTeam = scenarios.filter(
    (scenario) => scenario.teamId === teamId
  );
  const scenarioToApplyOperation = scenarios.find(
    (scenario) => scenario.id === scenarioId
  );
  if (!scenarioToApplyOperation) return false;
  if (operation === TeamScenarioOperationsEnum.CLONE_SCENARIO) {
    if (scenariosByTeam.length >= Number(TeamValuesEnum.MAX_SCENARIOS)) {
      return false;
    }
  }
  if (operationsAllowedOnlyOnFirstAndSecondScenario.includes(operation)) {
    return scenarioToApplyOperation.teamOrder < 2;
  }
  if (operationsAllowedWhenThereIs1Scenario.includes(operation)) {
    return scenariosByTeam.length === 1;
  }
  if (operationsAllowedWhenTheScenarioIsTheLast.includes(operation)) {
    return (
      scenariosByTeam[scenariosByTeam.length - 1].id ===
      scenarioToApplyOperation.id
    );
  }
  return true;
}

function editTeamScenarioField(
  scenarios: TeamScenarioProps[],
  teamScenarioId: string,
  field: string,
  value: string | number | TeamStateRolesProps[]
): TeamScenarioProps[] {
  return scenarios.map((scenario) => {
    if (scenario.id !== teamScenarioId) {
      return scenario;
    }
    const amountOfScenariosOfTeam = scenarios.filter(
      (scenarioByTeam) => scenarioByTeam.teamId === scenario.teamId
    ).length;
    if (scenario.teamOrder < amountOfScenariosOfTeam - 1 && field === "name") {
      return scenario;
    }
    if (
      scenario.teamOrder > 0 &&
      scenario.teamOrder < amountOfScenariosOfTeam - 1
    ) {
      return scenario;
    }
    return {
      ...scenario,
      [field]: value,
    };
  });
}

function propagateState(
  scenarios: TeamScenarioProps[],
  teamScenarioId: string,
  field: string,
  value: string | number | TeamStateRolesProps[]
): TeamScenarioProps[] {
  return scenarios.map((scenario) => {
    if (scenario.teamId !== teamScenarioId) {
      return scenario;
    }
    return {
      ...scenario,
      [field]: value,
    };
  });
}

function copyScenario(
  scenarios: TeamScenarioProps[],
  teamScenarioId: string,
  newName: string
): TeamScenarioProps | undefined {
  const scenarioToCopy = scenarios.find(
    (scenario) => scenario.id === teamScenarioId
  );
  if (!scenarioToCopy) return undefined;
  const teamScenarios = scenarios.filter(
    (scenario) => scenario.teamId === scenarioToCopy.teamId
  );
  if (!teamScenarios) return undefined;
  const newScenario: TeamScenarioProps = {
    ...scenarioToCopy,
    id: nanoid(),
    teamOrder: teamScenarios.length,
    name: newName,
  };
  return newScenario;
}

function copyScenarioResource(
  teamScenarioResources: TeamScenarioResourceProps[],
  teamScenarioId: string,
  newScenario: TeamScenarioProps,
  resourcesIds: { newId: string; previousId: string }[]
): TeamScenarioResourceProps[] {
  const resources = teamScenarioResources.filter(
    (teamScenarioResource) => teamScenarioResource.scenarioId === teamScenarioId
  );
  return resources.map((resource) => {
    const id = resourcesIds.find(
      (resourceId) => resourceId.previousId === resource.resourceId
    );
    return {
      ...resource,
      id: nanoid(),
      scenarioId: newScenario.id,
      resourceId: id?.newId || nanoid(),
    };
  });
}

function cloneScenario(
  scenarios: TeamScenarioProps[],
  teamScenarioResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  teamScenarioId: string,
  teamId: string,
  newName: string
): [TeamScenarioProps[], TeamScenarioResourceProps[], ResourceProp[]] {
  const shouldClone = allowOperationToScenario(
    scenarios,
    teamScenarioId,
    teamId,
    TeamScenarioOperationsEnum.CLONE_SCENARIO
  );
  if (!shouldClone) return [scenarios, teamScenarioResources, resources];
  const newScenario = copyScenario(scenarios, teamScenarioId, newName);
  if (!newScenario) return [scenarios, teamScenarioResources, resources];
  const newIds = resources
    .filter((resource) =>
      teamScenarioResources
        .filter(
          (scenarioResource) => scenarioResource.scenarioId === teamScenarioId
        )
        .map((scenarioResource) => scenarioResource.resourceId)
        .includes(resource.id)
    )
    .map((resource) => ({ previousId: resource.id, newId: nanoid() }));
  const newTeamScenarioResources = copyScenarioResource(
    teamScenarioResources,
    teamScenarioId,
    newScenario,
    newIds
  );
  if (!newTeamScenarioResources)
    return [scenarios, teamScenarioResources, resources];

  const newResources: ResourceProp[] = resources
    .filter((resource) =>
      teamScenarioResources
        .filter(
          (scenarioResource) => scenarioResource.scenarioId === teamScenarioId
        )
        .map((scenarioResource) => scenarioResource.resourceId)
        .includes(resource.id)
    )
    .map((resource) => {
      const id = newIds.find((newId) => newId.previousId === resource.id);
      return { ...resource, id: id?.newId || nanoid() };
    });

  if (!newResources) return [scenarios, teamScenarioResources, resources];
  return [
    [...scenarios, newScenario],
    [...teamScenarioResources, ...newTeamScenarioResources],
    [...resources, ...newResources],
  ];
}

function editTeamField(
  teams: TeamProps[],
  teamId: string,
  field: string,
  value: string | number
): TeamProps[] {
  return teams.map((team) => {
    if (team.id === teamId) {
      return { ...team, [field]: value };
    }
    return team;
  });
}

function getTeamScenarioNameSuggestions(
  scenarios: TeamScenarioProps[],
  teamId: string
): string {
  const scenarioNames = scenarios
    .filter((scenario) => scenario.teamId === teamId)
    .map((scenario) => scenario.name);
  const teamSuggestion = TEAM_STATES_SUGGESTIONS.find(
    (suggestion) => !scenarioNames.find((name) => name === suggestion)
  );
  if (teamSuggestion) {
    return teamSuggestion;
  }
  return `${DEFAULT_TEAM_SUGGESTION} (${scenarioNames.length + 1})`;
}
/**
 * This filters all the teams, and states to make sure only the selected team/state's role is replaced.
 * @param teams {TeamProps[]} Teams to be filtered
 * @param roles {RoleProps[]} Original roles
 * @param teamId {number} Team id that role will be replaced
 * @param stateId {number} Team State id that role will be replaced
 * @param roleId {number} Role id to be replaced by the number8 role.
 * @returns {TeamProps[]}
 */
function replaceResourceWithNumber8(
  resources: ResourceProp[],
  roles: RoleProps[],
  resourceId: string
): ResourceProp[] {
  return replaceByNumber8(resources, roles, resourceId);
}

function getTeamScenarioMembers(
  teamScenarioResource: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  currentScenario: TeamScenarioProps,
  roles: RoleProps[]
): ResourceWithRoleProps[] {
  const filteredScenarioResources = teamScenarioResource.filter(
    (scenarioResource) => scenarioResource.scenarioId === currentScenario.id
  );
  const filteredResources = resources.filter((resource) =>
    filteredScenarioResources
      .map((scenarioResource) => scenarioResource.resourceId)
      .includes(resource.id)
  );
  return buildStateRoleWithRoleDTO(
    filteredScenarioResources,
    filteredResources,
    roles
  );
}

function getTeamScenarioCPSPColor(
  resources: ResourceProp[],
  scenarios: TeamScenarioProps[],
  scenarioResources: TeamScenarioResourceProps[],
  teams: TeamProps[],
  roles: RoleProps[],
  teamId: string,
  scenarioId: string
): string {
  const selectedTeam = teams.find((team) => team.id === teamId);
  if (!selectedTeam) return "#666";
  const selectedScenario = scenarios.find(
    (teamScenario) => teamScenario.id === scenarioId
  );
  if (!selectedScenario) return "#666";
  if (selectedScenario.teamOrder === 0) return "#666";

  const currentScenario = scenarios.find(
    (teamScenario) =>
      teamScenario.teamId === teamId && teamScenario.teamOrder === 0
  );
  if (!currentScenario) return "#666666";

  // Current scenario is by which the selected scenario is being compared
  const currentScenarioResources = scenarioResources.filter(
    (scenarioResource) => scenarioResource.scenarioId === currentScenario.id
  );

  const currentScenarioResourceRoles: ResourceWithRoleProps[] =
    currentScenarioResources.map((scenarioResource) => ({
      role: roles.find(
        (role) =>
          role.id ===
          resources.find(
            (resource) => resource.id === scenarioResource.resourceId
          )?.roleId
      ),
      id: scenarioResource.id,
      seniority: scenarioResource.seniority,
      resourceAllocation: scenarioResource.resourceAllocation,
    }));
  const currentScenarioCPSP = calculateCPSP(
    getScenarioMembersTotalSalary(currentScenarioResourceRoles),
    currentScenario?.velocity || selectedTeam.velocity,
    currentScenario?.weeksPerSprint || selectedTeam.weeksPerSprint
  );
  const selectedScenarioResourcesId = scenarioResources.filter(
    (scenarioResource) => scenarioResource.scenarioId === selectedScenario.id
  );
  const selectedScenarioResourceRoles: ResourceWithRoleProps[] =
    selectedScenarioResourcesId.map((scenarioResource) => ({
      role: roles.find(
        (role) =>
          role.id ===
          resources.find(
            (resource) => resource.id === scenarioResource.resourceId
          )?.roleId
      ),
      id: scenarioResource.id,
      seniority: scenarioResource.seniority,
      resourceAllocation: scenarioResource.resourceAllocation,
    }));
  const selectedScenarioCPSP = calculateCPSP(
    getScenarioMembersTotalSalary(selectedScenarioResourceRoles),
    selectedScenario?.velocity ||
      currentScenario?.velocity ||
      selectedTeam.velocity,
    selectedScenario?.weeksPerSprint ||
      currentScenario?.weeksPerSprint ||
      selectedTeam.weeksPerSprint
  );
  return getCPSPColorCode(currentScenarioCPSP, selectedScenarioCPSP);
}

function replaceRoleWithAnotherRole(
  resources: ResourceProp[],
  resourceId: string,
  newRoleId: string
): ResourceProp[] {
  return resources.map((resource) => {
    if (resource.id !== resourceId) {
      return resource;
    }
    return { ...resource, roleId: newRoleId };
  });
}

function getLastCreatedTeam(teams: TeamProps[]): TeamProps {
  return teams.slice(-1)[0];
}

function addRoleToTeamScenario(
  resources: ResourceProp[],
  scenarioResources: TeamScenarioResourceProps[],
  scenarios: TeamScenarioProps[],
  roles: RoleProps[],
  scenarioId: string,
  teamId: string,
  roleId: string,
  seniority: RoleSeniority,
  resourceAllocation = 1,
  replacingRole = ""
): [ResourceProp[], TeamScenarioResourceProps[]] {
  const newResourceRole = roles.find((role) => role.id === roleId);
  if (!newResourceRole) return [resources, scenarioResources];

  const operation = newResourceRole.showNumber8UserIcon
    ? TeamScenarioOperationsEnum.ADD_N8_ROLE_TO_SCENARIO
    : TeamScenarioOperationsEnum.ADD_ROLE_TO_SCENARIO;
  const allowedOperation = allowOperationToScenario(
    scenarios,
    scenarioId,
    teamId,
    operation
  );
  if (!allowedOperation) return [resources, scenarioResources];
  if (resourceAllocation < 1) {
    const resourcesByRole = resources
      .filter((resource) => resource.roleId === roleId)
      .map((resource) => resource.id);
    const existsResourceAllowed = scenarioResources
      .filter((scenarioResource) =>
        resourcesByRole.includes(scenarioResource.resourceId)
      )
      .find(
        (scenarioResource) =>
          1 - scenarioResource.resourceAllocation >= resourceAllocation
      );
    if (existsResourceAllowed) {
      if (existsResourceAllowed.scenarioId === scenarioId) {
        const newScenarioResources = scenarioResources.map(
          (scenarioResource) => {
            if (scenarioResource.id === existsResourceAllowed.id) {
              return {
                ...scenarioResource,
                resourceAllocation:
                  scenarioResource.resourceAllocation + resourceAllocation,
              };
            }
            return scenarioResource;
          }
        );
        return [resources, newScenarioResources];
      }
      const newScenarioResource = {
        ...existsResourceAllowed,
        id: nanoid(),
        resourceAllocation,
        scenarioId,
      };
      return [resources, [...scenarioResources, newScenarioResource]];
    }
  }
  const newResource = {
    id: nanoid(),
    roleId: newResourceRole.id,
    seniority,
  };
  const newScenarioResource: TeamScenarioResourceProps = {
    id: nanoid(),
    resourceId: newResource.id,
    scenarioId,
    seniority,
    resourceAllocation,
  };
  if (replacingRole === "") {
    return [
      resources.concat(newResource),
      scenarioResources.concat(newScenarioResource),
    ];
  }
  return [
    resources.concat({ ...newResource, previousRole: replacingRole }),
    scenarioResources.concat(newScenarioResource),
  ];
}

function removeScenarioFromTeam(
  scenarios: TeamScenarioProps[],
  scenarioId: string,
  teamId: string
): TeamScenarioProps[] {
  const allowedOperation = allowOperationToScenario(
    scenarios,
    scenarioId,
    teamId,
    TeamScenarioOperationsEnum.DELETE_SCENARIO
  );
  if (!allowedOperation) return scenarios;
  return scenarios.filter((scenario) => scenario.id !== scenarioId);
}

function addScenario(
  stateScenario: TeamScenarioProps[],
  teamScenarioResource: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  selectedTeam: string,
  stateId: string,
  name: string,
  newTeamScenarioResource: TeamScenarioResourceProps[],
  newResources: ResourceProp[]
): [TeamScenarioProps[], TeamScenarioResourceProps[], ResourceProp[]] {
  const newStateScenario = [
    ...stateScenario,
    {
      id: stateId,
      name,
      teamId: selectedTeam,
      teamOrder: stateScenario.length,
      velocity: 100,
    },
  ];
  const TeamScenarioResource = [
    ...teamScenarioResource,
    ...newTeamScenarioResource,
  ];
  const totalResources = [...resources, ...newResources];

  return [newStateScenario, TeamScenarioResource, totalResources];
}

function removeResourceFromScenario(
  scenarioResources: TeamScenarioResourceProps[],
  scenarios: TeamScenarioProps[],
  resources: ResourceProp[],
  scenarioId: string,
  resourceId: string,
  teamId: string,
  roles: RoleProps[]
): TeamScenarioResourceProps[] {
  const resourceToRemove = resources.find(
    (resource) => resource.id === resourceId
  );
  if (!resourceToRemove) return scenarioResources;
  const roleToRemove = roles.find(
    (role) => role.id === resourceToRemove.roleId
  );
  if (!roleToRemove) {
    return scenarioResources.filter(
      (scenarioResource) =>
        scenarioResource.scenarioId !== scenarioId ||
        scenarioResource.resourceId !== resourceId
    );
  }
  const operation = roleToRemove.showNumber8UserIcon
    ? TeamScenarioOperationsEnum.REMOVE_N8_RESOURCE
    : TeamScenarioOperationsEnum.REMOVE_RESOURCE;
  const allowedOperation = allowOperationToScenario(
    scenarios,
    scenarioId,
    teamId,
    operation
  );
  if (!allowedOperation) return scenarioResources;
  return scenarioResources.filter(
    (scenarioResource) =>
      scenarioResource.scenarioId !== scenarioId ||
      scenarioResource.resourceId !== resourceId
  );
}

function updateTeamsBasedOnRoles(
  roles: RoleProps[],
  scenariosResources: TeamScenarioResourceProps[],
  resources: ResourceProp[]
): [TeamScenarioResourceProps[], ResourceProp[]] {
  const resourcesUsingExistingRoles = resources.filter((resource) =>
    roles.map((role) => role.id).includes(resource.roleId)
  );
  const scenariosResourcesUsingExistingRoles = scenariosResources.filter(
    (scenarioResource) =>
      resourcesUsingExistingRoles
        .map((resource) => resource.id)
        .includes(scenarioResource.resourceId)
  );
  return [scenariosResourcesUsingExistingRoles, resourcesUsingExistingRoles];
}

/**
 * This function returns a list of lists with the amount per role all the teams have in the current state, the last state and their difference
 * @param teams {TeamProps[]} Teams calculated
 * @param roles {RoleProps[]} Original roles
 * @returns {TeamsCurrentAndFutureMembersProps[]}
 */
function calculateAvailableResources(
  teams: TeamProps[],
  scenarios: TeamScenarioProps[],
  scenarioResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  roles: RoleProps[]
): TeamsCurrentAndFutureMembersProps {
  if (!roles || !teams) {
    return [[]];
  }
  const currentScenarios = scenarios.filter(
    (scenario) =>
      scenario.teamOrder.toString() === TeamValuesEnum.CURRENT_SCENARIO_ID
  );
  const currentScenarioResources = scenarioResources.filter(
    (scenarioResource) =>
      currentScenarios
        .map((scenario) => scenario.id)
        .includes(scenarioResource.scenarioId)
  );
  const currentStateRoles = roles.map((role) => {
    const resourcesUsingRole = {
      junior: 0,
      mid: 0,
      senior: 0,
    };
    const resourceAlreadyAccounted: string[] = [];
    currentScenarioResources.forEach((scenarioResource) => {
      const resource = resources.find(
        (resourc) =>
          resourc.id === scenarioResource.resourceId &&
          resourc.roleId === role.id
      );
      if (!resource || resourceAlreadyAccounted.includes(resource.id)) {
        return;
      }
      resourceAlreadyAccounted.push(resource.id);
      resourcesUsingRole[scenarioResource.seniority] +=
        scenarioResource.resourceAllocation;
    });
    return { role, amount: resourcesUsingRole };
  });
  const withN8Scenarios: TeamScenarioProps[] = teams.map((team) => {
    const teamsWithN8Scenarios = scenarios.filter(
      (scenario) => scenario.teamId === team.id
    );
    return teamsWithN8Scenarios[teamsWithN8Scenarios.length - 1];
  });
  const withN8ScenarioResources = scenarioResources.filter((scenarioResource) =>
    withN8Scenarios
      .map((scenario) => scenario.id)
      .includes(scenarioResource.scenarioId)
  );

  const withN8Roles = currentStateRoles.map((role) => {
    const resourcesUsingRole = {
      junior: 0,
      mid: 0,
      senior: 0,
    };
    withN8ScenarioResources.forEach((scenarioResource) => {
      const resource = resources.find(
        (resourc) =>
          resourc.id === scenarioResource.resourceId &&
          resourc.roleId === role.role.id
      );
      if (!resource) {
        return;
      }
      resourcesUsingRole[scenarioResource.seniority] +=
        scenarioResource.resourceAllocation;
    });

    return { role: role.role, amount: resourcesUsingRole };
  });

  const statesDifference = currentStateRoles.map((role) => {
    const amountInFutureState = withN8Roles.find(
      (roleInFutureState) => roleInFutureState.role.id === role.role.id
    );
    if (!amountInFutureState) {
      return role;
    }
    const amount = {
      junior: 0,
      mid: 0,
      senior: 0,
    };
    Object.keys(role.amount).forEach((seniority) => {
      const s = seniority as RoleSeniority;
      const difference = role.amount[s] - amountInFutureState.amount[s];
      const diff = Math.ceil(role.amount[s]) - amountInFutureState.amount[s];
      const isPartialAssign =
        difference === 0 && diff < 1 && role.amount[s] > 0;
      if (isPartialAssign) {
        amount[s] = diff;
      } else {
        amount[s] = difference >= 0 ? difference : 0;
      }
    });

    return { role: role.role, amount };
  });
  return [currentStateRoles, withN8Roles, statesDifference];
}

function isTeamNameInExistence(teams: TeamProps[], newName: string): boolean {
  return !!teams.find((team) => team.name === newName);
}

function teamsRoleIsBeingUsed(
  scenarios: TeamScenarioProps[],
  scenariosResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  roleId: string
): number {
  const resourcesUsingRole = resources
    .filter((resource) => resource.roleId === roleId)
    .map((resource) => resource.id);
  if (resourcesUsingRole.length === 0) return 0;
  const scenariosWhereResourceIsUsed = scenariosResources
    .filter((scenariosResource) =>
      resourcesUsingRole.includes(scenariosResource.resourceId)
    )
    .map((scenarioResource) => scenarioResource.scenarioId);
  if (scenariosWhereResourceIsUsed.length === 0) return 0;
  const teamsUsingRole = scenarios
    .filter((scenario) => scenariosWhereResourceIsUsed.includes(scenario.id))
    .map((scenario) => scenario.teamId);
  return [...new Set(teamsUsingRole)].length;
}

function duplicateTeam(
  teams: TeamProps[],
  scenarios: TeamScenarioProps[],
  scenariosResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  teamId: string
): [
  TeamProps[],
  TeamScenarioProps[],
  TeamScenarioResourceProps[],
  ResourceProp[],
  string
] {
  const teamToDuplicate = teams.find((team) => team.id === teamId);
  const teamToDuplicateScenarios = scenarios.filter(
    (scenario) => scenario.teamId === teamId
  );
  const teamToDuplicateScenariosResources = scenariosResources.filter(
    (scenarioResource) =>
      teamToDuplicateScenarios
        .map((scenario) => scenario.id)
        .includes(scenarioResource.scenarioId)
  );
  const resourcesDuplicatingTeamUses = resources.filter((resource) =>
    teamToDuplicateScenariosResources
      .map((scenarioResource) => scenarioResource.resourceId)
      .includes(resource.id)
  );
  if (!teamToDuplicate) {
    return [teams, scenarios, scenariosResources, resources, teamId];
  }
  const teamNameLength = teamToDuplicate.name.length;
  const amountOfTeamsWithSameName = teams.filter(
    (team) => team.name.substring(0, teamNameLength) === teamToDuplicate.name
  );
  const newTeam = {
    ...teamToDuplicate,
    name: `${teamToDuplicate.name} (${amountOfTeamsWithSameName.length + 1})`,
    id: nanoid(),
  };
  const newTeams = [...teams, newTeam];
  if (teamToDuplicateScenarios.length === 0) {
    return [newTeams, scenarios, scenariosResources, resources, newTeam.id];
  }
  const scenariosIds = teamToDuplicateScenarios.map((scenario) => ({
    previousId: scenario.id,
    newId: nanoid(),
  }));
  const duplicatedScenarios = teamToDuplicateScenarios.map((scenario) => ({
    ...scenario,
    id:
      scenariosIds.find((scenarioId) => scenarioId.previousId === scenario.id)
        ?.newId || nanoid(),
    teamId: newTeam.id,
  }));
  const newScenarios = [...scenarios, ...duplicatedScenarios];
  if (
    teamToDuplicateScenariosResources.length === 0 ||
    resourcesDuplicatingTeamUses.length === 0
  ) {
    return [newTeams, newScenarios, scenariosResources, resources, newTeam.id];
  }
  const resourcesIds = resourcesDuplicatingTeamUses.map((resource) => ({
    previousId: resource.id,
    newId: nanoid(),
  }));
  const newResources = resourcesDuplicatingTeamUses.map((resource) => ({
    ...resource,
    id:
      resourcesIds.find((resourceId) => resourceId.previousId === resource.id)
        ?.newId || nanoid(),
  }));
  const newScenarioResources: TeamScenarioResourceProps[] =
    teamToDuplicateScenariosResources.map((scenarioResource) => ({
      id: nanoid(),
      seniority: scenarioResource.seniority,
      resourceAllocation: scenarioResource.resourceAllocation,
      resourceId:
        resourcesIds.find(
          (resourceId) => resourceId.previousId === scenarioResource.resourceId
        )?.newId || nanoid(),
      scenarioId:
        scenariosIds.find(
          (scenarioId) => scenarioId.previousId === scenarioResource.scenarioId
        )?.newId || nanoid(),
    }));
  return [
    newTeams,
    newScenarios,
    [...scenariosResources, ...newScenarioResources],
    [...resources, ...newResources],
    newTeam.id,
  ];
}

function editTeam(
  teams: TeamProps[],
  scenarios: TeamScenarioProps[],
  teamId: string,
  editedTeam: TeamProps
): [TeamProps[], TeamScenarioProps[]] {
  const updatedTeams = teams.map((team) => {
    if (team.id === teamId) {
      return { ...editedTeam, id: teamId };
    }
    return team;
  });
  const currentScenario = scenarios.find(
    (scenario) => scenario.teamId === teamId && scenario.teamOrder === 0
  );
  if (!currentScenario) {
    return [teams, scenarios];
  }
  const updatedScenarios = scenarios.map((scenario) => {
    if (scenario.teamId === teamId && scenario.teamOrder === 0) {
      return {
        ...scenario,
        velocity: editedTeam.velocity,
        weeksPerSprint: editedTeam.weeksPerSprint,
      };
    }
    return scenario;
  });
  return [updatedTeams, updatedScenarios];
}

function resourceAvailability(
  teams: TeamProps[],
  scenarios: TeamScenarioProps[],
  scenarioResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  resourceId: string
): number {
  const resource = resources.find((resourc) => resourc.id === resourceId);
  if (!resource) return 0;
  const idOfScenariosUsingResource = scenarioResources
    .filter((scenarioResource) => scenarioResource.resourceId === resourceId)
    .map((scenarioResource) => scenarioResource.scenarioId);
  const scenariosUsingResource = scenarios.filter((scenario) =>
    idOfScenariosUsingResource.includes(scenario.id)
  );
  const teamIdsUsingResource = scenariosUsingResource.map(
    (scenario) => scenario.teamId
  );
  const teamsUsingResource = teams.filter((team) =>
    teamIdsUsingResource.includes(team.id)
  );
  // Since there is a chance a resource is replaced in the second scenario, we are going to check if
  // a resource is used in the last scenario of the teams.
  const relevantScenarios = teamsUsingResource
    .map((team) => {
      const teamScenarios = scenarios.filter(
        (scenario) => scenario.teamId === team.id
      );
      const lastScenario = teamScenarios.find(
        (scenario) => scenario.teamOrder === teamScenarios.length - 1
      );
      return lastScenario;
    })
    .filter(Boolean)
    .map((scenario) => scenario && scenario.id);
  const relevantScenarioResource = scenarioResources.filter(
    (scenarioResource) =>
      relevantScenarios.includes(scenarioResource.scenarioId) &&
      scenarioResource.resourceId === resourceId
  );
  const resourceAllocation = relevantScenarioResource
    .map((scenarioResource) => scenarioResource.resourceAllocation)
    .reduce((prev, current) => current + prev, 0);
  return 1 - resourceAllocation;
}

// Teams should have 2 scenarios to send avail resources since
// they will always be in the second scenario
function verifyTeamsHaveScenariosToAssignAvailableResources(
  scenarios: TeamScenarioProps[],
  scenarioResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  teamId: string
): [TeamScenarioProps[], TeamScenarioResourceProps[], ResourceProp[]] {
  const teamScenarios = scenarios.filter(
    (scenario) => scenario.teamId === teamId
  );
  if (teamScenarios.length > 1) {
    return [scenarios, scenarioResources, resources];
  }
  const [newScenarios, newTeamScenarioResources, newResources] = cloneScenario(
    scenarios,
    scenarioResources,
    resources,
    teamScenarios[0].id,
    teamId,
    "With number8"
  );
  return [newScenarios, newTeamScenarioResources, newResources];
}

function sendResourcesToTeam(
  teams: TeamProps[],
  scenarios: TeamScenarioProps[],
  scenariosResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  availableResources: RoleAmountProps[],
  teamId: string
): [TeamScenarioResourceProps[], TeamScenarioProps[], ResourceProp[]] {
  const teamToAddResources = teams.find((team) => team.id === teamId);
  if (!teamToAddResources) return [scenariosResources, scenarios, resources];
  let teamScenarios = scenarios.filter(
    (scenario) => scenario.teamId === teamId
  );
  if (teamScenarios.length === 0)
    return [scenariosResources, scenarios, resources];

  const [verifiedScenarios, verifiedScenarioResources, verifiedResources] =
    verifyTeamsHaveScenariosToAssignAvailableResources(
      scenarios,
      scenariosResources,
      resources,
      teamId
    );
  teamScenarios = verifiedScenarios.filter(
    (scenario) => scenario.teamId === teamId
  );
  const resourcesToAddToTeam = availableResources
    .map((availableResource) => {
      const unusedResources = Object.keys(availableResource.amount)
        .filter((roleSeniority) => {
          const amount =
            availableResource.amount[roleSeniority as RoleSeniority] > 0;
          return amount;
        })
        .map((roleSeniority) => {
          const amount =
            availableResource.amount[roleSeniority as RoleSeniority];

          const resourcesWithRoleAndSeniority = verifiedResources.filter(
            (resource) =>
              resource.roleId === availableResource.role.id &&
              resource.seniority === roleSeniority
          );
          const resourcesWithAvailability =
            resourcesWithRoleAndSeniority.filter((resource) => {
              const availability = resourceAvailability(
                teams,
                scenarios,
                scenariosResources,
                resources,
                resource.id
              );
              return availability > 0;
            });
          if (resourcesWithAvailability.length >= amount) {
            return resourcesWithAvailability;
          }
          return [];
        });
      return unusedResources.flat();
    })
    .flat();
  const newScenarioResources: TeamScenarioResourceProps[] = availableResources
    .map((availableResource) => {
      const resources1 = Object.keys(availableResource.amount)
        .map((roleSeniority) => {
          const individualResources = Array.from(
            Array(
              Math.floor(
                availableResource.amount[roleSeniority as RoleSeniority]
              )
            ),
            () => 1
          );
          const difference =
            availableResource.amount[roleSeniority as RoleSeniority] -
            Math.floor(
              availableResource.amount[roleSeniority as RoleSeniority]
            );
          if (difference > 0) {
            individualResources.push(difference);
          }
          return resourcesToAddToTeam
            .filter(
              (resource) =>
                resource.roleId === availableResource.role.id &&
                resource.seniority === roleSeniority
            )
            .slice(0, individualResources.length)
            .map((resource, index) => ({
              id: nanoid(),
              resourceId: resource.id,
              scenarioId: teamScenarios[1].id,
              seniority: resource.seniority,
              resourceAllocation: individualResources[index],
            }));
        })
        .flat();
      return resources1.flat();
    })
    .flat();
  return [
    [...verifiedScenarioResources, ...newScenarioResources],
    verifiedScenarios,
    verifiedResources,
  ];
}

function sendResourcesToCreateTeam(
  teams: TeamProps[],
  scenarios: TeamScenarioProps[],
  scenariosResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  availableResources: RoleAmountProps[],
  teamId: string
): [TeamScenarioResourceProps[], TeamScenarioProps[], ResourceProp[]] {
  const teamToAddResources = teams.find((team) => team.id === teamId);
  if (!teamToAddResources) return [scenariosResources, scenarios, resources];
  let teamScenarios = scenarios.filter(
    (scenario) => scenario.teamId === teamId
  );
  if (teamScenarios.length === 1)
    return [scenariosResources, scenarios, resources];

  const [verifiedScenarios, verifiedScenarioResources, verifiedResources] =
    verifyTeamsHaveScenariosToAssignAvailableResources(
      scenarios,
      scenariosResources,
      resources,
      teamId
    );
  teamScenarios = verifiedScenarios.filter(
    (scenario) => scenario.teamId === teamId
  );
  const resourcesToAddToTeam = availableResources
    .map((availableResource) => {
      const unusedResources = Object.keys(availableResource.amount)
        .filter((roleSeniority) => {
          const amount =
            availableResource.amount[roleSeniority as RoleSeniority] > 0;
          return amount;
        })
        .map((roleSeniority) => {
          const amount =
            availableResource.amount[roleSeniority as RoleSeniority];

          const resourcesWithRoleAndSeniority = verifiedResources.filter(
            (resource) =>
              resource.roleId === availableResource.role.id &&
              resource.seniority === roleSeniority
          );
          const resourcesWithAvailability =
            resourcesWithRoleAndSeniority.filter((resource) => {
              const availability = resourceAvailability(
                teams,
                scenarios,
                scenariosResources,
                resources,
                resource.id
              );
              return availability > 0;
            });
          if (resourcesWithAvailability.length >= amount) {
            return resourcesWithAvailability;
          }
          return [];
        });
      return unusedResources.flat();
    })
    .flat();
  const newScenarioResources: TeamScenarioResourceProps[] = availableResources
    .map((availableResource) => {
      const resources1 = Object.keys(availableResource.amount)
        .map((roleSeniority) => {
          const individualResources = Array.from(
            Array(
              Math.floor(
                availableResource.amount[roleSeniority as RoleSeniority]
              )
            ),
            () => 1
          );
          const difference =
            availableResource.amount[roleSeniority as RoleSeniority] -
            Math.floor(
              availableResource.amount[roleSeniority as RoleSeniority]
            );
          if (difference > 0) {
            individualResources.push(difference);
          }
          return resourcesToAddToTeam
            .filter(
              (resource) =>
                resource.roleId === availableResource.role.id &&
                resource.seniority === roleSeniority
            )
            .slice(0, individualResources.length)
            .map((resource, index) => ({
              id: nanoid(),
              resourceId: resource.id,
              scenarioId: teamScenarios[1].id,
              seniority: resource.seniority,
              resourceAllocation: individualResources[index],
            }));
        })
        .flat();
      return resources1.flat();
    })
    .flat();
  return [
    [...verifiedScenarioResources, ...newScenarioResources],
    verifiedScenarios,
    verifiedResources,
  ];
}

function addTeam(
  teams: TeamProps[],
  scenarios: TeamScenarioProps[],
  scenarioResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  newTeam: TeamProps,
  availableResources: RoleAmountProps[] = []
): [
  TeamProps[],
  TeamScenarioProps[],
  TeamScenarioResourceProps[],
  ResourceProp[]
] {
  const newTeamId = nanoid();
  localStorage.setItem("newId", newTeamId);
  const newTeamWithNewId: TeamProps = {
    ...newTeam,
    id: newTeamId,
  };
  const newScenario: TeamScenarioProps = {
    id: newTeam.id,
    name: TeamValuesEnum.DEFAULT_SCENARIO_NAME,
    teamId: newTeamId,
    teamOrder: 0,
  };
  const newTeams = [...teams, newTeamWithNewId];
  const newScenarios = [...scenarios, newScenario];
  const totalAssigned = availableResources
    .map((resource) => {
      const subTotal = RoleSeniorityList.map(
        (seniority) => resource.amount[seniority]
      ).reduce(sumReducer, 0);
      return subTotal;
    })
    .reduce(sumReducer, 0);
  if (totalAssigned > 0) {
    const [newScenarioResources, verifiedScenarios, verifiedResources] =
      sendResourcesToTeam(
        newTeams,
        newScenarios,
        scenarioResources,
        resources,
        availableResources,
        newTeamId
      );
    return [
      newTeams,
      verifiedScenarios,
      newScenarioResources,
      verifiedResources,
    ];
  }
  return [
    [...teams, newTeamWithNewId],
    [...scenarios, newScenario],
    scenarioResources,
    resources,
  ];
}

function createTeam(
  teams: TeamProps[],
  scenarios: TeamScenarioProps[],
  scenarioResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  newTeam: TeamProps,
  availableResources: RoleAmountProps[] = []
): [
  TeamProps[],
  TeamScenarioProps[],
  TeamScenarioResourceProps[],
  ResourceProp[]
] {
  const newTeamId = nanoid();
  localStorage.setItem("newId", newTeamId);
  const newTeamWithNewId: TeamProps = {
    ...newTeam,
    id: newTeamId,
  };
  const newScenario: TeamScenarioProps = {
    id: newTeam.id,
    name: TeamValuesEnum.DEFAULT_SCENARIO_NAME,
    teamId: newTeamId,
    teamOrder: 0,
  };
  const newTeams = [...teams, newTeamWithNewId];
  const newScenarios = [...scenarios, newScenario];
  const totalAssigned = availableResources
    .map((resource) => {
      const subTotal = RoleSeniorityList.map(
        (seniority) => resource.amount[seniority]
      ).reduce(sumReducer, 0);
      return subTotal;
    })
    .reduce(sumReducer, 0);
  if (totalAssigned > 0) {
    const [newScenarioResources, verifiedScenarios, verifiedResources] =
      sendResourcesToCreateTeam(
        newTeams,
        newScenarios,
        scenarioResources,
        resources,
        availableResources,
        newTeamId
      );
    return [
      newTeams,
      verifiedScenarios,
      newScenarioResources,
      verifiedResources,
    ];
  }
  return [
    [...teams, newTeamWithNewId],
    [...scenarios, newScenario],
    scenarioResources,
    resources,
  ];
}

function deleteTeam(
  teams: TeamProps[],
  scenarios: TeamScenarioProps[],
  scenarioResources: TeamScenarioResourceProps[],
  teamId: string
): [TeamProps[], string, TeamScenarioProps[], TeamScenarioResourceProps[]] {
  const newTeams = teams.filter((team) => team.id !== teamId);
  const lastTeam = newTeams[newTeams.length - 1].id;
  const teamIds = newTeams.map((team) => team.id);
  const scenariosOfExistingTeams = scenarios.filter((scenario) =>
    teamIds.includes(scenario.teamId)
  );
  const scenarioIds = scenariosOfExistingTeams.map((scenario) => scenario.id);
  const resourceScenariosOfExistingTeams = scenarioResources.filter(
    (resource) => scenarioIds.includes(resource.scenarioId)
  );
  return [
    newTeams,
    lastTeam,
    scenariosOfExistingTeams,
    resourceScenariosOfExistingTeams,
  ];
}

function changeTeamOrder(
  teams: TeamProps[],
  currentIndex: number,
  newIndex: number
): TeamProps[] {
  const teamToMove = teams[currentIndex];
  const newTeams = [
    ...teams.slice(0, currentIndex),
    ...teams.slice(currentIndex + 1),
  ];
  return [
    ...newTeams.slice(0, newIndex),
    teamToMove,
    ...newTeams.slice(newIndex),
  ];
}

export {
  addTeam,
  addScenario,
  createTeam,
  editTeamScenarioField,
  cloneScenario,
  getTeamScenarioNameSuggestions,
  editTeamField,
  getTeamScenarioCPSPColor,
  getLastCreatedTeam,
  replaceResourceWithNumber8,
  addRoleToTeamScenario,
  replaceRoleWithAnotherRole,
  removeResourceFromScenario,
  removeScenarioFromTeam,
  updateTeamsBasedOnRoles,
  calculateAvailableResources,
  isTeamNameInExistence,
  teamsRoleIsBeingUsed,
  duplicateTeam,
  editTeam,
  allowOperationToScenario,
  sendResourcesToTeam,
  sendResourcesToCreateTeam,
  resourceAvailability,
  deleteTeam,
  getTeamScenarioMembers,
  changeTeamOrder,
  propagateState,
};

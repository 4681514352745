import { Action, State } from "../types/context-props";
import TeamStateMovementEnum from "../assets/constants/TeamStateMovementEnum";
import TeamProps from "../types/team-props";
import ResourceProp from "../types/resource-prop";
import RoleAmountProps from "../types/role-amount-props";
import { ProTips } from "../types/pro-tips";
import { RoleSeniority } from "../assets/constants/RoleSeniority";
import { RoleSalary } from "../types/role-props";
import TeamScenarioResourceProps from "../types/team-scenariou-resource-props";

function deleteClientInfoAction(): Action {
  return {
    type: "deleteClientInfo",
  };
}

function saveAction(): Action {
  return {
    type: "save",
  };
}

function addTeamWithAvailableResourcesAction(
  team: TeamProps,
  roleAmount: RoleAmountProps[]
): Action {
  return {
    type: "addTeamWithAvailableResourcesAction",
    value: {
      team,
      roleAmount,
    },
  };
}

function createTeamWithAvailableResourcesAction(
  team: TeamProps,
  roleAmount: RoleAmountProps[]
): Action {
  return {
    type: "createTeamWithAvailableResourcesAction",
    value: {
      team,
      roleAmount,
    },
  };
}

function addRoleAction(
  name: string,
  abbreviation: string | undefined,
  annualSalary: RoleSalary,
  color: string,
  showNumber8UserIcon: boolean
): Action {
  return {
    type: "addRole",
    value: {
      annualSalary,
      abbreviation,
      name,
      color,
      showNumber8UserIcon,
    },
  };
}

function deleteRoleAction(id: string, color: string): Action {
  return {
    type: "deleteRole",
    value: { id, color },
  };
}

function updateRoleAction(
  roleId: string,
  field: string,
  value: string | RoleSalary
): Action {
  return {
    type: "updateRole",
    value: { field, roleId, value },
  };
}

function updateTeamStateField(
  teamId: string,
  stateId: string,
  field: "roles" | "name" | "velocity" | "weeksPerSprint",
  value: string | number | ResourceProp[]
): Action {
  return {
    type: "updateTeamStateField",
    value: { teamId, stateId, field, value },
  };
}

function propagateStateChange(
  field: "roles" | "name" | "velocity" | "weeksPerSprint", // roles not implemented yet
  value: string | number | ResourceProp[]
): Action {
  return {
    type: "propagateState",
    value: { field, value },
  };
}

function addTeamAction(newTeam: TeamProps): Action {
  return { type: "addTeam", value: newTeam };
}

function cloneStateAction(
  teamId: string,
  stateId: string,
  newName: string
): Action {
  return {
    type: "cloneStateOfTeam",
    value: { teamId, stateId, newName },
  };
}
function addStateAction(
  stateId: string,
  name: string,
  newTeamScenarioResource: TeamScenarioResourceProps[],
  newResources: ResourceProp[]
): Action {
  return {
    type: "addStateToTeam",
    value: { stateId, name, newTeamScenarioResource, newResources },
  };
}

function selectTeam(value: string): Action {
  return { type: "selectTeam", value };
}

function updateTeam(teamId: string, field: string, value: string): Action {
  return {
    type: "updateTeam",
    value: {
      teamId,
      field,
      value,
    },
  };
}

function deleteTeam(teamId: string): Action {
  return {
    type: "deleteTeam",
    value: teamId,
  };
}

function replaceRoleWithNumber8Action(resourceId: string): Action {
  return {
    type: "replaceRoleWithNumber8",
    value: resourceId,
  };
}

function addRoleToStateAction(
  teamId: string,
  stateId: string,
  roleId: string,
  seniority: RoleSeniority,
  resourceAllocation = 1,
  replacingRoleId = ""
): Action {
  return {
    type: "addRoleToState",
    value: {
      roleId,
      stateId,
      teamId,
      seniority,
      resourceAllocation,
      replacingRoleId,
    },
  };
}

function replaceRoleWithRole8Action(
  resourceId: string,
  newRoleId: string
): Action {
  return {
    type: "replaceRoleWithRole",
    value: { resourceId, newRoleId },
  };
}

function removeTeamMemberAction(
  teamId: string,
  stateId: string,
  resourceId: string,
  color = ""
): Action {
  return {
    type: "removeTeamMember",
    value: {
      resourceId,
      stateId,
      teamId,
      color,
    },
  };
}

function deleteStateAction(stateId: string, teamId: string): Action {
  return {
    type: "deleteState",
    value: {
      teamId,
      stateId,
    },
  };
}

function loadAppStateAction(state: State): Action {
  return {
    type: "setState",
    value: state,
  };
}

function moveStateAction(
  teamId: string,
  stateId: string,
  movement: TeamStateMovementEnum
): Action {
  return {
    type: "moveState",
    value: {
      teamId,
      stateId,
      movement,
    },
  };
}

function redoAction(): Action {
  return {
    type: "redo",
  };
}

function undoAction(): Action {
  return {
    type: "undo",
  };
}

function duplicateTeamAction(teamId: string): Action {
  return {
    type: "duplicateTeam",
    value: teamId,
  };
}

function setClientAction(name: string, contact: string, image: string): Action {
  return {
    type: "setClient",
    value: {
      name,
      contact,
      image,
    },
  };
}

function editTeamAction(
  teamId: string,
  team: TeamProps,
  editedScenario?: RoleAmountProps[]
): Action {
  return {
    type: "editTeam",
    value: {
      team,
      teamId,
      editedScenario,
    },
  };
}

function setUserInfoAction(
  name: string,
  email: string,
  phoneNumber: string
): Action {
  return {
    type: "setUserInfo",
    value: {
      email,
      name,
      phoneNumber,
    },
  };
}

function updateClientInfoAction(
  name: string,
  contact: string,
  image: string
): Action {
  return {
    type: "updateClientInfoAction",
    value: {
      name,
      contact,
      image,
    },
  };
}

function assignResourcesToTeamAction(
  teamId: string,
  resources: RoleAmountProps[]
): Action {
  return {
    type: "assignResourcesToTeam",
    value: {
      teamId,
      resources,
    },
  };
}

function setShowProTips(showProTips: ProTips): Action {
  return {
    type: "setShowProTips",
    value: {
      showProTips,
    },
  };
}

function changeTeamOrder(currentIndex: number, newIndex: number): Action {
  return {
    type: "changeTeamOrder",
    value: {
      currentOrder: currentIndex,
      newOrder: newIndex,
    },
  };
}

export {
  addRoleAction,
  deleteRoleAction,
  updateRoleAction,
  addTeamAction,
  updateTeamStateField,
  cloneStateAction,
  selectTeam,
  updateTeam,
  replaceRoleWithNumber8Action,
  addRoleToStateAction,
  deleteTeam,
  replaceRoleWithRole8Action,
  removeTeamMemberAction,
  deleteStateAction,
  loadAppStateAction,
  moveStateAction,
  undoAction,
  redoAction,
  duplicateTeamAction,
  setClientAction,
  editTeamAction,
  setUserInfoAction,
  assignResourcesToTeamAction,
  addTeamWithAvailableResourcesAction,
  createTeamWithAvailableResourcesAction,
  saveAction,
  deleteClientInfoAction,
  updateClientInfoAction,
  setShowProTips,
  changeTeamOrder,
  addStateAction,
  propagateStateChange,
};

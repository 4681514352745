import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Typography, Col, Modal, Row, Input, Divider, Button } from "antd";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import EditTeamModalProps from "../../types/edit-team-modal-props";
import RoleAmountProps from "../../types/role-amount-props";
import { useAppContext } from "../../Context/context";
import EditTeamForm from "../EditTeamForm/EditTeamForm";
import UserIcon from "../UserIcon/UserIcon";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";
import {
  editTeamAction,
  deleteStateAction,
  addStateAction,
} from "../../Context/actions";
import {
  RoleSeniority,
  RoleSeniorityList,
} from "../../assets/constants/RoleSeniority";
import { convertRoleNameToPascal } from "../../helpers/roles";
import TeamScenarioResourceProps from "../../types/team-scenariou-resource-props";

const { Title } = Typography;

const reducer = (prev: number, curr: number) => curr + prev;

const EditTeamModalResources: FC<EditTeamModalProps> = ({ close }) => {
  const {
    dispatch,
    state: {
      selectedTeam,
      teams,
      roles,
      resources,
      teamScenario,
      teamScenarioResource,
    },
  } = useAppContext();
  const [assignedRoleToTeam, setAssignedRoleToTeam] = useState<
    RoleAmountProps[]
  >([]);

  const [name, setName] = useState<string>("");
  const [scenarioId, setScenarioId] = useState<string>("");
  const [velocity, setVelocity] = useState<number>(100);
  const [weeksPerSprint, setWeeksPerSprint] = useState<number>(2);
  const [scenarioCount, setScenarioCount] = useState<number>(0);
  const [isRepeatedName, setIsRepeatedName] = useState<boolean>(false);
  const [scenarioChanged, setScenarioChanged] = useState<boolean>(false);

  const getTeam = useCallback(() => {
    return teams.find((team) => team.id === selectedTeam);
  }, [selectedTeam, teams]);

  useEffect(() => {
    const currentTeam = getTeam();
    const teamScenarios = teamScenario.filter(
      (scenario) => scenario.teamId === selectedTeam
    );
    const selectedScenario = teamScenarios.find(
      (scenario) => scenario.teamOrder === 0
    );

    if (selectedScenario) {
      setScenarioId(selectedScenario.id);
    }
    if (currentTeam) {
      setName(currentTeam?.name || "");
      setVelocity(currentTeam.velocity);
      setWeeksPerSprint(currentTeam.weeksPerSprint);
    }
    setScenarioCount(teamScenarios.length);
  }, [
    getTeam,
    resources,
    roles,
    scenarioId,
    selectedTeam,
    teamScenario,
    teamScenarioResource,
  ]);

  useEffect(() => {
    // Resources of the first scenario
    if (scenarioId) {
      const firstScenarioId = scenarioId;
      const currentResources = teamScenarioResource.filter((tsr) => {
        return tsr.scenarioId === firstScenarioId;
      });
      setAssignedRoleToTeam(
        roles.map((role) => {
          const juniorResources = currentResources.filter((cr) =>
            resources.find(
              (r) =>
                r.id === cr.resourceId &&
                r.seniority === RoleSeniority.JUNIOR &&
                r.roleId === role.id
            )
          );
          const juniorD = juniorResources.reduce(
            (acc, act) => acc + act.resourceAllocation,
            0
          );
          const midResources = currentResources.filter((cr) =>
            resources.find(
              (r) =>
                r.id === cr.resourceId &&
                r.seniority === RoleSeniority.MID &&
                r.roleId === role.id
            )
          );
          const midD = midResources.reduce(
            (prev, act) => prev + act.resourceAllocation,
            0
          );
          const seniorResources = currentResources.filter((cr) =>
            resources.find(
              (r) =>
                r.id === cr.resourceId &&
                r.seniority === RoleSeniority.SENIOR &&
                r.roleId === role.id
            )
          );
          const seniorD = seniorResources.reduce(
            (prev, act) => prev + act.resourceAllocation,
            0
          );
          return {
            role,
            amount: { junior: juniorD, mid: midD, senior: seniorD },
          };
        })
      );
    }
  }, [
    roles,
    selectedTeam,
    teamScenario,
    teamScenarioResource,
    scenarioId,
    resources,
  ]);

  const calculateAssignedResources = (resources1: RoleAmountProps[]) => {
    const total = resources1
      .map((resource) => {
        const subTotal = RoleSeniorityList.map(
          (seniority) => resource.amount[seniority]
        ).reduce(reducer, 0);
        return subTotal;
      })
      .reduce(reducer, 0);
    return total;
  };

  const calculateSeniorityResources = (
    resources1: RoleAmountProps[],
    seniority: RoleSeniority
  ) => {
    const total = resources1
      .map((resource) => {
        return resource.amount[seniority];
      })
      .reduce(reducer, 0);
    return total;
  };

  const assignRoleToTeam = (
    roleId: string,
    seniority: RoleSeniority,
    amount: string,
    limit: number
  ) => {
    setScenarioChanged(true);
    const newAmount = Number(amount);
    if (newAmount <= limit && newAmount >= 0) {
      const newAssignedRole = assignedRoleToTeam.map((assignedRole) => {
        if (assignedRole.role.id === roleId) {
          return {
            ...assignedRole,
            amount: {
              ...assignedRole.amount,
              [seniority]: Number(amount),
            },
          };
        }
        return assignedRole;
      });
      setAssignedRoleToTeam(newAssignedRole);
    }
  };

  const onChangeTeamName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);
  };

  const onChangeVelocity = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setVelocity(Number(value));
  };

  const onChangeWeeksPerSprint = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setWeeksPerSprint(Number(value));
  };

  const saveEditTeam = useCallback(() => {
    if (teams.find((t) => t.name === name && t.id !== selectedTeam)) {
      setIsRepeatedName(true);
      return;
    }
    setIsRepeatedName(false);

    const currentTeam = getTeam();
    if (currentTeam === undefined) {
      close();
      return;
    }
    const editedTeam = {
      ...currentTeam,
      name,
      velocity,
      weeksPerSprint,
    };
    if (scenarioChanged) {
      // Delete the past scenario
      const deletedScenario = teamScenario.find(
        (ts) => ts.teamId === selectedTeam
      );
      if (deletedScenario) {
        const deletedName = deletedScenario.name;
        dispatch(deleteStateAction(deletedScenario.id, selectedTeam));
        // Create a new copy of the scenario
        const newResources = assignedRoleToTeam.filter((sr) => {
          return (
            sr.amount.junior > 0 || sr.amount.mid > 0 || sr.amount.senior > 0
          );
        });
        const newStateId = nanoid();
        const insertTeamScenario: TeamScenarioResourceProps[] = [];
        // Adjust resources new number
        const insertResources = newResources.flatMap((nr) => {
          const finalArray = [];
          if (Number(nr.amount.junior) > 0) {
            for (let i = 0; i < nr.amount.junior; i += 1) {
              finalArray.push({
                id: nanoid(),
                roleId: nr.role.id,
                seniority: RoleSeniority.JUNIOR,
              });
            }
          }
          if (Number(nr.amount.mid) > 0) {
            for (let i = 0; i < nr.amount.mid; i += 1) {
              finalArray.push({
                id: nanoid(),
                roleId: nr.role.id,
                seniority: RoleSeniority.MID,
              });
            }
          }
          if (Number(nr.amount.senior) > 0) {
            for (let i = 0; i < nr.amount.senior; i += 1) {
              finalArray.push({
                id: nanoid(),
                roleId: nr.role.id,
                seniority: RoleSeniority.SENIOR,
              });
            }
          }
          finalArray.forEach((r) => {
            insertTeamScenario.push({
              id: nanoid(),
              resourceId: r.id,
              scenarioId: newStateId,
              resourceAllocation:
                nr.amount[r.seniority] >= 1 ? 1 : nr.amount[r.seniority],
              seniority: r.seniority,
            });
            nr.amount[r.seniority] -= 1;
          });
          return finalArray;
        });
        dispatch(
          addStateAction(
            newStateId,
            deletedName,
            insertTeamScenario,
            insertResources
          )
        );
      }
    }
    dispatch(editTeamAction(selectedTeam, editedTeam));
    close();
  }, [
    assignedRoleToTeam,
    close,
    dispatch,
    getTeam,
    name,
    scenarioChanged,
    selectedTeam,
    teamScenario,
    teams,
    velocity,
    weeksPerSprint,
  ]);
  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void }) => {
      if (event.code !== "Enter" && event.code !== "NumpadEnter") {
        return;
      }
      event.preventDefault();
      saveEditTeam();
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [saveEditTeam]);

  return (
    <Modal
      visible
      onCancel={close}
      title={
        <Title level={3} style={{ marginBottom: 0 }}>
          Edit Team With Resources
        </Title>
      }
      centered
      width={600}
      data-cy="editTeamModal"
      footer={[
        <Button key="back" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={saveEditTeam}
          data-cy="editTeamSubmitBtn"
        >
          Save
        </Button>,
      ]}
    >
      <EditTeamForm
        name={name || ""}
        onChangeTeamName={onChangeTeamName}
        onChangeTeamVelocity={onChangeVelocity}
        onChangeWeeksPerSprint={onChangeWeeksPerSprint}
        velocity={velocity || 100}
        weeksPerSprint={weeksPerSprint || 2}
        scenarios={scenarioCount}
      />
      <Row justify="space-between" className="resources-titles">
        <Col span={9} style={{ marginLeft: "10px" }} />
        {RoleSeniorityList.map((roleSeniority) => (
          <Col span={2} className="resources-titles-seniority">
            {convertRoleNameToPascal(roleSeniority)}
          </Col>
        ))}
        <Col
          span={2}
          className="resources-titles-seniority"
          style={{ fontSize: "10px", marginTop: "5px" }}
        >
          Assigning
        </Col>
        <div style={{ maxHeight: "400px", overflow: "auto" }}>
          {roles
            .filter((resource) => !resource.showNumber8UserIcon)
            .map((resource) => {
              const assignedResource = assignedRoleToTeam.find(
                (assignedRole) => assignedRole.role.id === resource.id
              );
              if (!assignedResource) return null;
              return (
                <Row
                  key={resource.id}
                  align="middle"
                  justify="space-between"
                  className="role"
                >
                  <Col span={1}>
                    <UserIcon
                      color={resource.color}
                      showNumber8UserIcon={resource.showNumber8UserIcon}
                      size={UserIconSizeEnum.LARGE}
                    />
                  </Col>
                  <Col span={4}>{resource.name}</Col>
                  <Col span={1}>x</Col>
                  {RoleSeniorityList.map((resourceAmountKey) => (
                    <>
                      <Col span={2}>
                        <Input
                          value={assignedResource.amount[resourceAmountKey]}
                          min={0}
                          onChange={(event) =>
                            assignRoleToTeam(
                              resource.id,
                              resourceAmountKey,
                              event.target.value,
                              100
                            )
                          }
                          size="small"
                          type="number"
                          style={{ padding: 0 }}
                        />
                      </Col>
                    </>
                  ))}
                  <Col span={2} style={{ textAlign: "center" }}>
                    {RoleSeniorityList.reduce(
                      (previousValue, currentValue) =>
                        previousValue + assignedResource.amount[currentValue],
                      0
                    )}
                  </Col>
                </Row>
              );
            })}
        </div>
      </Row>
      <Divider />
      <Row>
        <Col span={11}>Team members</Col>
        {RoleSeniorityList.map((seniority, i, arr) => (
          <Col span={i === arr.length - 1 ? 3 : 4}>
            {calculateSeniorityResources(assignedRoleToTeam, seniority)}
          </Col>
        ))}
        <Col span={2} style={{ textAlign: "center" }}>
          {calculateAssignedResources(assignedRoleToTeam)}
        </Col>
      </Row>
      {isRepeatedName && (
        <Modal
          data-cy="cantCreateTeamModal"
          visible
          title="Can't create team"
          footer={null}
          onCancel={() => setIsRepeatedName(false)}
        >
          That team name is already used. Please type a different name
        </Modal>
      )}
    </Modal>
  );
};

EditTeamModalResources.propTypes = {
  close: PropTypes.func.isRequired,
};

export default EditTeamModalResources;
